.carousel {
    border-radius: 1.5rem;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 75%;
}

.carousel-buttons {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: 1%;

    .selected-button {
        margin-top: 35%;
        margin-bottom: 35%;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        cursor: pointer;
    }

    .unselected-button {
        margin-top: 35%;
        margin-bottom: 35%;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: rgb(107 114 128);
        cursor: pointer;
    }
}

.carousel-body {
    width: 100%;
}

.carousel-item {
    background-color: transparent;
    position: relative;
    display: flex;
    height: 512px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .img-container {
        position: relative;
        display: flex;
        right: 10%;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        width: 33.333333%;
        background-color: #ffffff;
        height: 340px;

        img {
            width: 75%;
            height: 75%;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: end;
        width: 66.666667%;
        height: 66.666667%;
        margin-bottom: 4%;

        h1 {
            margin: 0;
            margin-left: 5%;
            font-size: 2.25rem;
            font-weight: normal;
            color: #ffffff;
            font-size: 70px;
        }

        p {
            margin: 0;
            margin-left: 5%;
            width: 75%;
            text-align: start;
            color: #28474B;
            font-size: 31px;
        }
    }
}