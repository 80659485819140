.timeline {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;

    #wroadmap {
        transform: translateY(-400%);
    }

    .events {
        margin: 0;
        margin-left: 8%;
        display: flex;
        justify-content: start;
        align-items: start;

        .date {
            flex: none;
            width: 10%;
            padding-top: 3.25%;
            margin-right: 1.5rem;
            color: #445f62;
        }

        .event {
            display: flex;
            padding-right: 1.4%;
            padding-top: 3%;
            padding-bottom: 3%;
            flex-direction: row;
            flex-grow: 1;
            border-left: 6px solid #F47B66;
            justify-content: start;
            align-items: start;

            span {
                height: 2px;
                width: 2px;
                cursor: pointer;
                border-radius: 100%;
                border: 6px solid #F47B66;
                transform: translate(-60%);
                padding: 0.5%;
                margin-right: 2.5%;

                &.expanded {
                    background-color: #fff;
                }

                &.not-expanded {
                    background-color: #F47B66;
                }
            }

            .event-description {
                color: #445f62;
                background-color: transparent;
                transition: all 0.3s ease-in-out;

                &.expanded {
                    background-color: #F47B66;
                    color: #fff;
                    width: 50%;
                    border: 4px solid #F47B66;
                    border-radius: 25px;
                    padding: 2.5%;
                    text-align: start;
                }

                h4 {
                    text-decoration: none;
                    font-weight: normal;
                    font-size: 25px;
                    cursor: pointer;
                    margin: 0;
                    margin-bottom: 4%;
                }

                div {
                    margin: 0;
                    font-weight: normal;
                }
            }
        }
    }
}