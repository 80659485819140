.roadmap {
    margin-top: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;

    .decorative {
        transform: translateX(-70%);
        border: 8px #F47B66 solid;
        width: 137px;
        height: 750px;
        border-radius: 100px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;

        h1 {
            transform: translate(-12%);
            text-decoration: none;
            font-weight: normal;
            font-size: 70px;
            color: #F47B66;
            margin: 0;
            margin-top: 1%;
        }
    }

    img {
        display: flex;
        transform: translateX(-50%);
        width: 200%;
        height: 200%;
        filter: blur(10px);
    }
}