.topbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 65px;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 4px solid #e5cdb1;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1000;

    .title {
        margin-left: 10%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        img {
            width: 45px;
            height: 45px;
        }

        h1 {
            color: #e5cdb1;
            font-weight: normal;
        }
    }

    .tabs {
        display: flex;
        flex-direction: row;
        color: #e5cdb1;
        margin-right: 10%;

        button {
            font-family: "Inter", sans-serif;
            margin-left: 1.5rem;
            cursor: pointer;
            text-decoration: none;
            font-weight: normal;
            color: #e5cdb1;
            background: none;
            border: none;
            font-size: 23px;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }
}