.team {
    background-color: #1e4651;
    width: 100%;
    padding-top: 6%;
    padding-bottom: 6%;

    #wwhoarewe {
        
        transform: translateY(-200%);
    }

    h2 {
        color: #e85d3f;
        font-size: 41px;
        font-weight: normal;
        margin-bottom: 5%;
    }

    .team-container {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        // background: red;
        margin: 0 auto;

        .team-item {
            width: calc(20% - 10px);
            margin: 5px;
            // text-align: center;
            // box-sizing: border-box;
            // background: red;
            // height: 100%;

            img {
                width: 100%;
                height: auto;
            }

            .member-description {
                // position: relative;
                background-color: #00000085;
                text-align: center;
                padding: 2%;
                color: #fff;
                // width: 100%;
                height: 67%;
                // right:0;
                transform: translateY(-101%);
                pointer-events: none;

                h3 {
                    font-weight: normal;
                    text-decoration: none;
                }
            }
        }

        .team-item:nth-child(-n + 4) {
            width: calc(25% - 5%);
            transform: translateX(30%);

            .member-description {
                position: absolute;
                background-color: #00000085;
                text-align: center;
                padding: 2%;
                color: #fff;
                width: calc(100% - 4%);
                height: calc(100% - 6%);
                transform: translateX(10%);
                transform: translateY(calc(-100% - 5px));
                pointer-events: none;


            //     h3 {
            //         font-weight: normal;
            //         text-decoration: none;
            //     }
            }
        }
    }
}