.home {
    background-color: #f0f0f0;
    overflow-x: hidden;

    h1 {
      font-size: 2em;
    }

    p {
      margin-top: 10px;
    }

    
}

::-webkit-scrollbar {
  width: 0px;
}
  ::-webkit-scrollbar-track {
  background-color: transparent; 
}
  ::-webkit-scrollbar-thumb {
  background: #E6CDB1; 
    border-radius:10px;
}
::-webkit-scrollbar-thumb:hover { 
  cursor:n-resize;
}