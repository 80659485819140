.whitespace {
    height: 65px;
    width: 100%;
}

.collibri-story {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #28474B;

    .title {
        h1 {
            margin: 0;
            color: #FEA66F;
            font-size: 110px;
            text-decoration: none;
            font-weight: normal;
        }

        h2 {
            transform: translateY(-50%);
            margin: 0;
            font-size: 31px;
            text-decoration: none;
            font-weight: normal;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        img {
            width: 33%;
        }

        p {
            width: 30%;
            font-size: 31px;
            text-align: start;
        }
    }

    .catch-phrase {
        font-size: 31px;
        color: #408C82;

        span {
            color: #E85D3F;
            font-weight: 600;
        }
    }

    .description {
        margin-top: 5%;
        width: 80%;
        font-size: 31px;
        color: #28474B;
        text-align: start;
    }
}

.actions {
    margin-top: 9%;
    margin-bottom: 9%;
    display: flex;
    justify-content: center;
}

.infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #28474B;

    .section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        justify-items: center;
        align-items: center;
        margin-bottom: 70px;

        img {
            width: 33%;
        }

        .content {
            width: 33%;

            h2 {
                line-height: 63px;
                text-decoration: none;
                font-weight: normal;
                font-size: 70px;
                color: #F47B66;
                text-align: start;
                margin: 0;
            }

            p {
                margin: 0;
                margin-top: 20px;
                font-size: 31px;
                text-align: start;
            }
        }
    }
}