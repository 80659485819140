.landing-screen {
    text-align: left;
    width: 100%;
    
    .video-copyright {
        color: white;
        position: absolute;
        transform: translateY(-25px); 
        right: 0;
    }

    .title-container {
        display: block;
        position: absolute;
        left: 10%;
        top: 40%;

        h1 {
            color: #FFE4C8;
            font-size: 179px;
            font-weight: normal;
            margin-bottom: 0;
        }

        h2 {
            color: #FEA66F;
            font-size: 45px;
            font-weight: normal;
            margin-top: 0;
        }

        img {
            display: block;
            position: absolute;
            width: 90px;
            height: 90px;
            top: 135px;
            left: 218px;
        }

    }
}